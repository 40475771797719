import { gql } from '@apollo/client';
import { IMAGE_VARIANT_FRAGMENT, VIDEO_FRAGMENT } from './media.fragments';
import { BASE_PARTNER_COMPANY_FRAGMENT } from './partner.fragments';
import {
  BASE_FARM_SITE_FRAGMENT,
  COLLECTION_SITE_FRAGMENT,
  DISTRIBUTION_SITE_FRAGMENT,
  FACTORY_SITE_FRAGMENT,
  FOREST_SITE_FRAGMENT,
  GENERIC_SITE_FRAGMENT,
  GROCERY_STORE_SITE_FRAGMENT,
  HANDLING_SITE_FRAGMENT,
  MANUFACTURING_SITE_FRAGMENT,
  MINE_SITE_FRAGMENT,
  ORIGIN_SITE_FRAGMENT,
  PLANT_SITE_FRAGMENT,
  PROCESSING_SITE_FRAGMENT,
  SITE_CLUSTER_FRAGMENT,
  WAREHOUSE_SITE_FRAGMENT,
} from './site.fragments';

export const BASE_ACTIVITY_FRAGMENT = gql`
  fragment baseActivityValues on Activity {
    id
    title
    modifiedTimestamp: createdTimestamp
  }
`;

export const ACTIVITY_FRAGMENT = gql`
  fragment activityValues on Activity {
    ...baseActivityValues
    langs
    description
    mediaList {
      ... on Video {
        ...video
      }
      ... on ImageVariant {
        ...imageVariant
      }
    }
    ownedBy {
      ...basePartnerCompanyValues
    }
    component {
      id
      title
      rawMaterials {
        id
        title
      }
    }
    subActivities {
      id
      title
    }
    site {
      ... on GenericSite {
        ...genericSiteValues
      }
      ... on Farm {
        ...baseFarmSiteValues
      }
      ... on Mine {
        ...mineValues
      }
      ... on Forest {
        ...forestValues
      }
      ... on CollectionSite {
        ...collectionSiteValues
      }
      ... on OriginSite {
        ...originSiteValues
      }
      ... on Plant {
        ...plantValues
      }
      ... on ProcessingSite {
        ...processingSiteValues
      }
      ... on Factory {
        ...factoryValues
      }
      ... on ManufacturingSite {
        ...manufacturingSiteValues
      }
      ... on Warehouse {
        ...warehouseValues
      }
      ... on HandlingSite {
        ...handlingSiteValues
      }
      ... on GroceryStore {
        ...groceryStoreValues
      }
      ... on DistributionSite {
        ...distributionSiteValues
      }
      ... on SiteCluster {
        ...siteClusterValues
      }
      __typename
    }
  }
  ${BASE_PARTNER_COMPANY_FRAGMENT}
  ${BASE_ACTIVITY_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
  ${VIDEO_FRAGMENT}
  ${GENERIC_SITE_FRAGMENT}
  ${BASE_FARM_SITE_FRAGMENT}
  ${MINE_SITE_FRAGMENT}
  ${FOREST_SITE_FRAGMENT}
  ${COLLECTION_SITE_FRAGMENT}
  ${ORIGIN_SITE_FRAGMENT}
  ${PLANT_SITE_FRAGMENT}
  ${PROCESSING_SITE_FRAGMENT}
  ${FACTORY_SITE_FRAGMENT}
  ${MANUFACTURING_SITE_FRAGMENT}
  ${WAREHOUSE_SITE_FRAGMENT}
  ${HANDLING_SITE_FRAGMENT}
  ${GROCERY_STORE_SITE_FRAGMENT}
  ${DISTRIBUTION_SITE_FRAGMENT}
  ${SITE_CLUSTER_FRAGMENT}
`;
