import { ISubActivity } from 'types/activity.types';
import { MultiPolygon } from 'types/map.types';
import { MediaItem } from 'types/media.types';
import { Coordinates } from 'types/types';

export enum ActivityOwnership {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
  UNASSIGNED = 'UNASSIGNED',
}

export type ActivityOwnershipType = `${ActivityOwnership}`;
export interface CreateActivityInput {
  mediaList?: MediaItem[];
  createdTimestamp?: string;
  componentId?: string | null;
  description?: string;
  title?: string;
  name?: string | null;
  locationCoordinates?: Coordinates | null;
  ownedById?: string | null;
  coordinates?: MultiPolygon;
  externalId?: string;
  siteId?: string | null;
  subActivities?: ISubActivity[];
}
export interface CreatePartnerActivityInput {
  ownedById: string;
  activityTitle: string;
}

export interface UpdatePartnerActivityInput {
  title: string;
}

export type UpdateActivityInput = CreateActivityInput;
