import { useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import DatasetForm from 'components/ComponentsLibrary/Forms/DatasetForm';
import useDatasetMutation from 'components/ComponentsLibrary/hooks/useDatasetMutation';
import DatasetVerification from 'components/DataImport/Views/DatasetVerification';
import WarningBanner from 'components/DataImport/WarningBanner';
import { useDocumentUpload } from 'components/DocumentLibrary/hooks';
import DatasetValidationForm, {
  IDatasetTableHandle,
} from 'components/DueDiligenceProcess/components/Forms/DatasetValidationForm';
import { Loader } from 'components/Forms';
import { ErrorState, FlexBox, PageContainer, PageTitle } from 'components/Structure';
import { useDialog, useMessages, usePageTitle, useUploadState } from 'components/hooks';
import { originTableSettingsSchema } from 'constants/schemas/compliance.schema';
import {
  datasetSchema,
  IDatasetFormValue,
  IGeoUploadFormValue,
} from 'constants/schemas/geoUpload.schema';
import { useBlockNavigation } from 'contexts/BlockNavigationContext';
import { ThemeButton, ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { Formik, FormikProps } from 'formik';
import { DELETE_DOCUMENTS } from 'graphql/mutations';
import useDatasetOverview from 'hooks/useDatasetOverview';
import isEqual from 'lodash/isEqual';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useMeasure from 'react-use/lib/useMeasure';
import { IOriginTableSettings } from 'types/dataImport.types';
import { EDatasetExportFormat, EDatasetExportType, EDatasetStatus } from 'types/dataset.types';
import { DocumentCategories } from 'types/document.types';
import { transformColumnNamesToTableSettingColumns } from 'utils/dataset.utils';

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  minHeight: 50,
  padding: theme.spacing(0.5),
  marginTop: theme.spacing(2),
}));

const DatasetOverview: FC = () => {
  usePageTitle('Origin data import');
  const { dataset, partner, partnerId, error, loading, isPartnerLibrary, refetch } =
    useDatasetOverview();
  const [uploadFile] = useDocumentUpload();
  const { createDataset, updateDataset, exportDataset, startDatasetPreProcessing } =
    useDatasetMutation();
  const { handleCleanCreatedRecords, cleanFiles } = useUploadState();
  const { openDialog } = useDialog();
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const location = useLocation();

  const datasetTableRef = useRef<IDatasetTableHandle>(null);
  const formRef = useRef<FormikProps<IDatasetFormValue>>(null);
  const [deleteDocument] = useMutation(DELETE_DOCUMENTS);

  const [containerRef, { height: containerHeight }] = useMeasure<HTMLDivElement>();

  const [isPreparingExport, setIsPreparingExport] = useState(false);
  const [tableSettings, setTableSettings] = useState<IOriginTableSettings>(
    originTableSettingsSchema.default()
  );

  const { block, unblock } = useBlockNavigation();

  /**
   * Initial cleanup of files that were uploaded but not attached to a dataset
   */
  useEffect(() => {
    cleanFiles();
    handleCleanCreatedRecords();
    return () => unblock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !error &&
      (dataset?.status === EDatasetStatus.CREATED ||
        dataset?.status === EDatasetStatus.VALIDATION_FAILED)
    ) {
      block();
    } else {
      unblock();
    }
  }, [dataset, error, block, unblock]);

  const initialValues: IDatasetFormValue = {
    ...datasetSchema.default(),
    ...(partner ? { ownedBy: partner } : {}),
    ...(dataset
      ? {
          datasetId: dataset.id,
          title: dataset.title,
          rawMaterialId: dataset.rawMaterial?.id,
          countryCode: dataset.originCountry,
          ownedBy: dataset.ownedBy,
        }
      : {}),
  };

  // TODO: Move the row error count calculation to the backend
  const issueCount =
    dataset?.tableRepresentation?.[0]?.rows?.reduce(
      (prev, row) => prev + (Object.values(row).some(value => value?.error) ? 1 : 0),
      0
    ) || 0;

  const blockNavigation = () => {
    block({
      alertDialogConfig: {
        title: 'Discard dataset',
        content:
          'Your dataset has formatting errors and can not be saved until these errors are corrected. If you exit without saving, your data import will be lost. Do you want to continue to edit your import?',
      },
    });
  };

  const unblockAndUpdateNavigation = useCallback(
    (datasetId: string) => {
      // Finally let the router detect the URL changes that could have been blocked so far from the empty table creation
      if (!location.pathname.match(/\/datasets\/[0-9a-fA-F-]{36}/)) {
        unblock({ pathname: `${location.pathname}/${datasetId}` });
      } else {
        unblock(location);
      }
    },
    [location, unblock]
  );

  const updateDatasetIfNeeded = async (
    values: Partial<
      Pick<IGeoUploadFormValue, 'title' | 'rawMaterialId' | 'countryCode' | 'ownedBy'>
    > & {
      datasetId: string; // datasetid is required
      files?: { id: string }[];
    },
    skipCacheUpdate?: boolean
  ) => {
    if (values.datasetId && !isEqual(initialValues, values)) {
      return await updateDataset(
        {
          ...values,
        },
        skipCacheUpdate
      );
    }
  };

  const handleSubmit = async (values: IDatasetFormValue) => {
    if (!formRef.current) {
      return;
    }

    // If the dataset is already after normalization, update it and run the processing again
    if (
      dataset?.id &&
      (dataset.status === EDatasetStatus.VERIFICATION_FAILED ||
        dataset.status === EDatasetStatus.COMPLETED)
    ) {
      formRef.current.setSubmitting(true);
      const updateDatasetResponse = await updateDatasetIfNeeded({
        ...values,
        datasetId: dataset.id,
      });
      formRef.current.setSubmitting(false);
      if (updateDatasetResponse?.data?.updateDataset) {
        setSuccessMessage('Dataset successfully updated');
      }
      return;
    }

    // Validate / export table data
    try {
      let currentDatasetId: string | undefined = values.datasetId;

      if (
        !datasetTableRef.current ||
        !datasetTableRef.current.getDataAsCsv ||
        !datasetTableRef.current.runValidations
      ) {
        throw new Error('Could not access the table data');
      }

      // Run validations
      const isValid = datasetTableRef.current.runValidations();

      if (!isValid) {
        setErrorMessage('Please correct the validation errors in the table');
        formRef.current.setSubmitting(false);
        return;
      }

      // Extract data as csv from table representation
      const csvData = datasetTableRef.current.getDataAsCsv({
        removeFirstAndLastColumn: true,
        // By default the column id is used as header and adds a space based on the camel case
        processHeaderCallback: ({ column }) => column.getColId(),
      });

      // Create a file from the csv data
      const file = new File([csvData], `${values.title}-validated.csv`, {
        type: 'text/csv',
      });

      // Upload the file to the document library
      // @ts-ignore
      const documentCreationResponse = await uploadFile({
        file,
        category: DocumentCategories.GEOGRAPHICAL_FEATURES,
      });

      if (!documentCreationResponse?.record.id) {
        throw new Error('Could not create the document');
      }

      // Create dataset if datasetId does not exist in the form
      // We do not want to update the cache here since the dataset ends up in status CREATED until the processing is done.
      // This created a flickering effect and the dataset gets anyway update in the processing
      if (!currentDatasetId) {
        const datasetCreationResult = await createDataset(
          {
            ...values,
            files: [{ id: documentCreationResponse.record.id }],
          },
          true
        );

        currentDatasetId = datasetCreationResult.data?.createDataset.dataset.id;
        refetch({ id: currentDatasetId });
        // Routing to the new dataset without performing a rerender that would reset the form
        window.history.replaceState(null, '', `datasets/${currentDatasetId}`);
        // Update the form with the new dataset id
        formRef.current.setValues({ ...formRef.current.values, datasetId: currentDatasetId });
      } else {
        // Delete old document on the dataset
        if (dataset?.documents?.length) {
          deleteDocument({ variables: { ids: dataset.documents.map(({ id }) => id) } });
        }

        // We do not want to update the cache here since the dataset ends up in status CREATED until the processing is done.
        // This created a flickering effect and the dataset gets anyway update in the processing
        await updateDatasetIfNeeded(
          {
            ...values,
            datasetId: currentDatasetId,
            files: [{ id: documentCreationResponse.record.id }],
          },
          true
        );
      }

      if (!currentDatasetId) {
        throw new Error('Could not reference the dataset correctly');
      }
      const datasetId = currentDatasetId;

      const mappedColumns = datasetTableRef.current.getColumnsMapping();

      const preProcessingResponse = await startDatasetPreProcessing([{ datasetId, mappedColumns }]);

      if (
        preProcessingResponse.data?.preProcessDatasets.datasetProcessingErrors?.length ||
        preProcessingResponse.data?.preProcessDatasets.datasets.some(
          dataset => dataset.status !== EDatasetStatus.VALIDATED
        )
      ) {
        formRef.current?.setSubmitting(false);
        setErrorMessage('Please correct all validation errors in the table');
        unblockAndUpdateNavigation(datasetId);
        return;
      }

      formRef.current?.setSubmitting(false);

      // Create / update the dataset and show confirmation dialogs
      openDialog({
        type: 'CONFIRM_DATASET_IMPORT',
        props: {
          values: {
            overwriteExisting: tableSettings.overwriteExisting,
            clusterSites: tableSettings.clusterSites,
          },
          mappedColumns,
          datasetId,
          onProcessingStart: () => formRef.current?.setSubmitting(true),
          onProcessingComplete: () => {
            formRef.current?.setSubmitting(false);
            unblockAndUpdateNavigation(datasetId);
          },
        },
      });
    } catch (error) {
      console.error('Error while saving the dataset', error);
      formRef.current?.setSubmitting(false);
      setErrorMessage('Could not save the dataset. Please try again or contact the support');
    }
  };

  const onTableSettingsChange = useCallback(
    (settings: IOriginTableSettings) => {
      setTableSettings(settings);
    },
    [setTableSettings]
  );

  const handleOpenSettings = () => {
    openDialog({
      type: 'DATASET_TABLE_SETTINGS',
      props: { settings: tableSettings, onTableSettingsChange },
    });
  };

  const handleClickDownload = async (datasetId: string) => {
    try {
      setIsPreparingExport(true);
      const response = await exportDataset({
        datasetId,
        exportType: EDatasetExportType.VALIDATED,
        exportFormat: EDatasetExportFormat.EXCEL,
      });
      const fileUrl = response?.data?.exportDataset?.fileUrl;
      if (fileUrl) {
        // Wait for the file to actually be ready to be downloaded
        await setTimeout(() => window.open(fileUrl, '_blank'), 500);
      } else {
        throw new Error('Could not export the dataset');
      }
    } catch (error) {
      setErrorMessage('Could not export the dataset. Please try again or contact the support');
    } finally {
      setIsPreparingExport(false);
    }
  };

  /**
   * Initially enable the columns in the tableSettings that are already included in the dataset
   */
  useEffect(() => {
    const datasetTableRepresentation = dataset?.tableRepresentation?.[0];
    if (
      (dataset?.status === EDatasetStatus.VALIDATION_FAILED ||
        dataset?.status === EDatasetStatus.VALIDATED) &&
      datasetTableRepresentation
    ) {
      setTableSettings(settings => ({
        ...settings,
        columns: transformColumnNamesToTableSettingColumns(datasetTableRepresentation.columnsNames),
      }));
    }
  }, [dataset]);

  if (
    loading ||
    ((dataset?.status === EDatasetStatus.VALIDATED ||
      dataset?.status === EDatasetStatus.VALIDATION_FAILED) &&
      !dataset.tableRepresentation)
  ) {
    return <Loader />;
  }
  if (error) {
    return <ErrorState />;
  }

  return (
    <PageContainer>
      <Box ref={containerRef}>
        <Box mb={2}>
          <PageTitle
            title="Origin data import"
            goBackLabel="All datasets"
            goBackUrl={isPartnerLibrary ? `/partners/${partnerId}/imports` : '/imports'}
            TitleAdornment={
              <FlexBox ml={1} mt={0.5}>
                <Icon name="farm" color="gray-60" size="large" />
                <Box mr={0.5} />
                <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_60">
                  Farm data
                </ThemeTypography>
              </FlexBox>
            }
          />
        </Box>

        <Formik<IDatasetFormValue>
          innerRef={formRef}
          enableReinitialize
          validationSchema={datasetSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {props => (
            <DatasetForm
              {...props}
              isTableView={
                !dataset ||
                dataset?.status === EDatasetStatus.CREATED ||
                dataset?.status === EDatasetStatus.VALIDATED ||
                dataset?.status === EDatasetStatus.VALIDATION_FAILED
              }
              disableOwnerSelection={
                isPartnerLibrary || dataset?.status === EDatasetStatus.COMPLETED
              }
              onTableSettingsClick={handleOpenSettings}
              onFormChange={blockNavigation}
            />
          )}
        </Formik>

        {(dataset?.status === EDatasetStatus.CREATED ||
          dataset?.status === EDatasetStatus.VALIDATION_FAILED ||
          dataset?.status === EDatasetStatus.VALIDATED) && (
          <>
            {dataset.status === EDatasetStatus.VALIDATION_FAILED &&
              dataset.tableRepresentation?.length && (
                <Box mt={2}>
                  <WarningBanner
                    severity="error"
                    message={`Dataset could not be saved. ${issueCount} rows contain errors. Please correct all errors per row in the table below. Click on Save & verify to check whether all errors are resolved.`}
                    issueCount={issueCount}
                  />
                  <Box mt={2} />
                  <ThemeButton
                    color="WHITE"
                    loading={isPreparingExport}
                    startIcon={<Icon name="download" />}
                    onClick={() => handleClickDownload(dataset.id)}
                  >
                    Download file with highlighted issues
                  </ThemeButton>
                </Box>
              )}
          </>
        )}
      </Box>

      {/* Subtract the top container height - 56px margin & padding to use the available height for the table */}
      <Container height={`calc(100% - ${containerHeight}px - 56px)`}>
        {/* Show either the validation / empty table or the verification screen */}
        {dataset?.status === EDatasetStatus.VERIFICATION_FAILED ||
        dataset?.status === EDatasetStatus.COMPLETED ? (
          <DatasetVerification dataset={dataset} pageSubTitle="Farms in dataset" />
        ) : (
          <DatasetValidationForm
            datasetTableRef={datasetTableRef}
            tableRepresentation={dataset?.tableRepresentation?.[0]}
            tableSettings={tableSettings}
            height="100%"
            mode="add-edit"
          />
        )}
      </Container>
    </PageContainer>
  );
};

export default DatasetOverview;
