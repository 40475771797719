import { Box, styled } from '@mui/material';
import useDatasetMutation from 'components/ComponentsLibrary/hooks/useDatasetMutation';
import { useDueDiligenceProcess } from 'components/DueDiligenceProcess/Context/DueDiligenceProcessContext';
import DueDiligenceProcessSubSectionNavigation from 'components/DueDiligenceProcess/DueDiligenceProcessSubSectionNavigation';
import { ErrorState, FlexBox } from 'components/Structure';
import {
  AddBatchDetailsFormValues,
  addBatchDetailsSchema,
  batchDetailSchema,
} from 'constants/schemas/riskAnalysis.schema';
import { InfoTooltip, ThemeTypography } from 'designSystem';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Switch } from 'formik-mui';
import pick from 'lodash/pick';
import React, { FC, useMemo, useRef } from 'react';
import DatasetAccordionField from '../Forms/DatasetAccordionField';

const SwitchContainer = styled(FlexBox)(({ theme }) => ({
  background: theme.custom.themeColors.primary[5],
  width: 'fit-content',
  borderRadius: 8,
  padding: theme.spacing(0, 2),
}));

const InfoContainer = styled(FlexBox)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: '6px',
  border: `1px solid ${theme.custom.themeColors.primary[40]}`,
  background: theme.custom.themeColors.primary[5],
}));

const RiskAnalysisBatches: FC = () => {
  const formRef = useRef<FormikProps<AddBatchDetailsFormValues>>(null);
  const { dueDiligenceProcess } = useDueDiligenceProcess();
  const { submitEudrRiskAnalysis } = useDatasetMutation();

  const initialValues: AddBatchDetailsFormValues = useMemo(
    () => ({
      ...addBatchDetailsSchema.default(),
      datasetBatchRelation:
        dueDiligenceProcess?.datasets.map(dataset => ({
          datasetId: dataset.id,
          ...pick(dataset, ['ownedBy', 'title', 'originCountry', 'rawMaterial']),
          batches: [batchDetailSchema.default()],
        })) || [],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!dueDiligenceProcess) {
    return <ErrorState />;
  }

  const handleSubmit = async (values: AddBatchDetailsFormValues) => {
    try {
      await submitEudrRiskAnalysis({
        geoDataProcessId: dueDiligenceProcess.id,
        datasetBatches: values.datasetBatchRelation.map(({ datasetId, batches }) => ({
          datasetId,
          batches,
        })),
      });
    } catch (error) {
      console.error(error);
    } finally {
      formRef.current?.setSubmitting(false);
    }
  };

  return (
    <Formik<AddBatchDetailsFormValues>
      innerRef={formRef}
      validationSchema={addBatchDetailsSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ values, isValid, isSubmitting, submitForm }) => {
        // TODO: fix that, somehow the validation is not working on initial values
        const everyDatasetHasBatches = values.datasetBatchRelation.every(
          ({ batches }) => batches.length > 0 && batches.every(batch => batch.checkDate)
        );

        return (
          <Form>
            <DueDiligenceProcessSubSectionNavigation
              allowNextStepNavigation={isValid && everyDatasetHasBatches}
              nextStepLoading={isSubmitting}
              customNextStepButton={{ text: 'Start risk analysis', startIcon: 'analysis' }}
              onNextStepClick={submitForm}
            >
              <Box display="flex" flexDirection="column" gap={2}>
                <ThemeTypography variant="BODY_MEDIUM">
                  An estimated check date for each dataset is required to run the deforestation
                  analysis. The analysis will search for deforestation risks at this origin over
                  time from the regulation cut-off date (31 December 2020) up until the check date
                  you select for each origin batch. The check date should be the harvest date of the
                  origin batch. You can later update the check date and re-run the analysis without
                  extra cost for the same identical plots. A correct check date and quantity per
                  batch are later required to be included before submitting a due diligence
                  statement. Commodity trade type (HS code) and product description are required
                  before exporting the obligatory public report, but this can also be added later.
                  You need to add at least one origin batch per origin.
                </ThemeTypography>

                <InfoContainer>
                  <ThemeTypography variant="BODY_MEDIUM">
                    If you don&apos;t know the origin batch details yet, you can add an arbitrary
                    check date (e.g. today), and add the correct details for each origin batch later
                    on (latest by the day of submitting a statement to the EU).
                  </ThemeTypography>
                </InfoContainer>

                <SwitchContainer>
                  <ThemeTypography variant="BODY_MEDIUM">
                    Auto-assign unique origin batch IDs
                  </ThemeTypography>
                  <Field
                    name="autoAssignIds"
                    fullWidth
                    component={Switch}
                    type="checkbox"
                    label="Auto-assign unique origin batch IDs"
                  />
                  <InfoTooltip
                    variant="INFO"
                    text="If you are using id numbers to identify different batches, it is better to use your existing id numbers. If not, you can enable here to assign an id to each new row. This will ensure a unique id for every batch."
                  />
                </SwitchContainer>

                <Box width="100%" display="flex" flexDirection="column">
                  {values.datasetBatchRelation.map(({ datasetId }, datasetIndex) => {
                    const dataset = dueDiligenceProcess?.datasets.find(
                      dataset => dataset.id === datasetId
                    );

                    return (
                      <Field
                        key={datasetId}
                        name={`datasetBatchRelation.[${datasetIndex}]`}
                        dataset={dataset}
                        component={DatasetAccordionField}
                      />
                    );
                  })}
                </Box>
              </Box>
            </DueDiligenceProcessSubSectionNavigation>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RiskAnalysisBatches;
