import { Box, styled } from '@mui/material';
import DatasetForm from 'components/ComponentsLibrary/Forms/DatasetForm';
import useDatasetMutation from 'components/ComponentsLibrary/hooks/useDatasetMutation';
import DatasetVerification from 'components/DataImport/Views/DatasetVerification';
import { useDueDiligenceProcess } from 'components/DueDiligenceProcess/Context/DueDiligenceProcessContext';
import { useGeoDataCollection } from 'components/DueDiligenceProcess/Context/SubSections/GeoDataCollectionContext';
import DueDiligenceProcessSubSectionNavigation from 'components/DueDiligenceProcess/DueDiligenceProcessSubSectionNavigation';
import { useMessages } from 'components/hooks';
import {
  datasetSchema,
  IDatasetFormValue,
  IGeoUploadFormValue,
} from 'constants/schemas/geoUpload.schema';
import DatasetItem from 'designSystem/DataDisplay/DatasetItem/DatasetItem';
import { Formik, FormikProps } from 'formik';
import isEqual from 'lodash/isEqual';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { booleanish } from 'types/booleanish.types';
import { EDatasetStatus } from 'types/dataset.types';

const DatasetContainer = styled('div')<{ disabled?: booleanish }>(({ disabled }) => ({
  cursor: disabled === 'true' ? 'initial' : 'pointer',
}));

const GeoDataCollectionVerification: FC = () => {
  const { setErrorMessage } = useMessages();
  const { dueDiligenceProcess } = useDueDiligenceProcess();
  const { updateDataset } = useDatasetMutation();
  const { onNextClick, onPreviousClick } = useGeoDataCollection();
  const formRef = useRef<FormikProps<IDatasetFormValue>>(null);

  const [selectedDatasetId, setSelectedDatasetId] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const selectedDataset = useMemo(
    () => dueDiligenceProcess?.datasets.find(dataset => dataset.id === selectedDatasetId),
    [dueDiligenceProcess, selectedDatasetId]
  );

  const sortedDatasets = useMemo(
    () =>
      dueDiligenceProcess?.datasets
        ? [...dueDiligenceProcess.datasets].sort((a, b) => (a.title <= b.title ? -1 : 1))
        : [],
    [dueDiligenceProcess]
  );

  const initialValues: IDatasetFormValue = {
    ...datasetSchema.default(),
    ...(selectedDataset
      ? {
          datasetId: selectedDataset.id,
          title: selectedDataset.title,
          rawMaterialId: selectedDataset.rawMaterial.id,
          countryCode: selectedDataset.originCountry,
          ownedBy: selectedDataset.ownedBy,
        }
      : {}),
  };

  useEffect(() => {
    if (!dueDiligenceProcess) {
      return;
    }
    // find the first dataset that is has the status 'VERIFICATION_FAILED'
    const failedDataset = dueDiligenceProcess.datasets.find(
      dataset => dataset.status === EDatasetStatus.VERIFICATION_FAILED
    );
    if (failedDataset) {
      setSelectedDatasetId(failedDataset.id);
    } else if (dueDiligenceProcess.datasets.length) {
      // Otherwise select the first dataset
      setSelectedDatasetId(dueDiligenceProcess.datasets[0].id);
    }
  }, [dueDiligenceProcess]);

  const updateDatasetIfNeeded = async (
    values: Partial<
      Pick<IGeoUploadFormValue, 'title' | 'rawMaterialId' | 'countryCode' | 'ownedBy'>
    > & {
      datasetId: string; // datasetid is required
      files?: { id: string }[];
    }
  ) => {
    if (values.datasetId && !isEqual(initialValues, values)) {
      return await updateDataset({
        ...values,
      });
    }
  };

  const handleSubmitVerification = async (values: IDatasetFormValue) => {
    if (selectedDatasetId) {
      try {
        setLoading(true);
        return await updateDatasetIfNeeded({
          datasetId: selectedDatasetId,
          ...formRef.current?.values,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error while saving the dataset', error);
        setErrorMessage('Could not save the dataset. Please try again or contact the support');
      } finally {
        setLoading(false);
      }
    }
  };

  /**
   * To be able to switch between datasets we need to save the changes of the current dataset
   */
  const handleDatasetClick = async (datasetId: string) => {
    if (selectedDatasetId) {
      try {
        setLoading(true);
        await updateDatasetIfNeeded({
          datasetId: selectedDatasetId,
          ...formRef.current?.values,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error while saving the dataset', error);
        setErrorMessage('Could not save the dataset. Please try again or contact the support');
      } finally {
        setLoading(false);
      }
    }
    setSelectedDatasetId(datasetId);
  };

  const handleNextStepClick = async () => {
    if (selectedDatasetId) {
      if (formRef.current?.values) {
        const response = await handleSubmitVerification(formRef.current.values);

        const updatedDataset = response?.data?.updateDataset.dataset;
        const remainingDatasets = dueDiligenceProcess?.datasets.filter(
          dataset => dataset.id !== updatedDataset?.id
        );

        if (
          (!updatedDataset || updatedDataset.status === EDatasetStatus.COMPLETED) &&
          remainingDatasets?.every(dataset => dataset.status === EDatasetStatus.COMPLETED)
        ) {
          onNextClick();
        }
      }
    } else {
      onNextClick();
    }
  };

  return (
    <Formik<IDatasetFormValue>
      innerRef={formRef}
      enableReinitialize
      validationSchema={datasetSchema}
      initialValues={initialValues}
      onSubmit={handleSubmitVerification}
    >
      {formProps => (
        <>
          <DueDiligenceProcessSubSectionNavigation
            allowNextStepNavigation
            nextStepLoading={loading}
            onNextStepClick={handleNextStepClick}
            onPreviousStepClick={onPreviousClick}
          >
            <Box display="flex" gap={2} flexWrap="wrap">
              {sortedDatasets.map(dataset => (
                <DatasetContainer key={dataset.id} onClick={() => handleDatasetClick(dataset.id)}>
                  <DatasetItem
                    title={dataset.title}
                    commodity={dataset.rawMaterial.title}
                    location={dataset.originCountry}
                    owner={dataset.ownedBy.name}
                    active={dataset.id === selectedDataset?.id}
                    status={dataset.status === EDatasetStatus.COMPLETED ? 'valid' : 'error'}
                  />
                </DatasetContainer>
              ))}
            </Box>
          </DueDiligenceProcessSubSectionNavigation>

          <Box display="flex" flexDirection="column" mt={2} gap={2}>
            <DatasetForm
              {...formProps}
              showOnlyEudrRelevantRawMaterials
              submitButtonText="Save and verify"
            />

            <Box minHeight={50} position="relative">
              {selectedDataset && (
                <DatasetVerification
                  dataset={selectedDataset}
                  isEUDR
                  pageSubTitle="Farms in dataset"
                />
              )}
            </Box>
          </Box>
        </>
      )}
    </Formik>
  );
};

export default GeoDataCollectionVerification;
