import React, { FC } from 'react';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material';

interface IRiskPieLabelProps {
  label: string;
  subLabel: string;
  fontSizeSublabel?: number;
  fontSizeLabel?: number;
}

const StyledLabel = styled('text')<{ fontSize?: number }>(({ theme, fontSize }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: fontSize ? fontSize : 30,
  fontFamily: theme.custom.fontFamilyTitle,
  fontWeight: 700,
}));

const StyledSubLabel = styled('tspan')<{ fontSize?: number }>(({ theme, fontSize }) => ({
  fontFamily: theme.custom.fontFamilyBase,
  fontSize: fontSize ? fontSize : 15,
  fontWeight: 400,
}));

const RiskPieLabel: FC<IRiskPieLabelProps> = ({
  label,
  subLabel,
  fontSizeLabel,
  fontSizeSublabel,
}) => {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledLabel x={left + width / 2} y={top + height / 2} fontSize={fontSizeLabel}>
      <tspan x={left + width / 2} dy="-0.2em">
        {label}
      </tspan>
      <StyledSubLabel x={left + width / 2} dy="1.4em" fontSize={fontSizeSublabel}>
        {subLabel}
      </StyledSubLabel>
    </StyledLabel>
  );
};

export default RiskPieLabel;
