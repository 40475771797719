import { Box, Grid, MenuItem } from '@mui/material';
import { FieldWrapper } from 'designSystem';
import TextField from 'designSystem/Formik/TextField/TextField';
import { FastField as Field, useField } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import React, { FC, useMemo } from 'react';
import { IChainActivity, IChainStep } from 'types/chain.types';
import { ShadowedFieldBlock, StepIndexNumber } from './styles';
import SubActiviesField from './SubActivitiesField';
import { IActivity } from 'types/activity.types';

interface IStepSelectorBlockProps {
  steps?: IChainStep[];
  disabled?: boolean;
  mode?: 'default' | 'translate';
}

const StepSelectorBlock: FC<IStepSelectorBlockProps> = ({ steps, disabled, mode }) => {
  const [chainStepField] = useField<IChainActivity['chainStepId'] | undefined>('chainStepId');
  const [subActivities] = useField<IActivity['subActivities'] | undefined>(
    'activity.subActivities'
  );

  const displaySubActivities = useMemo(() => {
    if (mode === 'translate') {
      return (
        !!subActivities.value?.length &&
        subActivities.value.some(subActivity => !!subActivity.title)
      );
    }
    return true;
  }, [mode, subActivities.value]);

  const currentStepIndex = useMemo(
    () => steps?.findIndex(({ id }) => id === chainStepField.value),
    [steps, chainStepField.value]
  );

  const ActivityTitleField = (
    <FieldWrapper label="Activity title" required>
      <Field
        component={TextField}
        fullWidth
        type="text"
        name="activity.title"
        variant="outlined"
        size="small"
        placeholder="E.g. Harvest, Collection"
        data-cy="activity-detail-input"
        inputProps={{
          style: { fontSize: 14, height: 19 }, // Fix size difference between select and text input
          'data-cy': 'activity-detail-input',
        }}
        disabled={disabled}
      />
    </FieldWrapper>
  );

  if (!steps || currentStepIndex === undefined) {
    return (
      <ShadowedFieldBlock container>
        <Grid item xs={12}>
          {ActivityTitleField}
        </Grid>
        {displaySubActivities && (
          <Grid item xs={12}>
            <Box mt={2}>
              <FieldWrapper>
                <Field component={SubActiviesField} name="activity.subActivities" mode={mode} />
              </FieldWrapper>
            </Box>
          </Grid>
        )}
      </ShadowedFieldBlock>
    );
  }

  return (
    <ShadowedFieldBlock container>
      <Grid item xs={6}>
        <FieldWrapper
          label="Chain step"
          tooltip={{
            variant: 'INFO',
            helperText:
              'Select what type of step in the chain this activity is part of (E.g. Origin, Processing or Manufacturing), and enter the activity detail to describe what the activity is specifically about (E.g. Harvest, Grinding or Molding).',
          }}
          required
        >
          <Box display="flex" alignItems="center" gap={1.5} mr={2}>
            <StepIndexNumber>{currentStepIndex + 1}</StepIndexNumber>

            <Field
              component={FormikTextField}
              fullWidth
              type="text"
              name="chainStepId"
              variant="outlined"
              select
              size="small"
              disabled={disabled}
            >
              {steps.map(step => (
                <MenuItem key={step.id} value={step.id}>
                  {step.title}
                </MenuItem>
              ))}
            </Field>
          </Box>
        </FieldWrapper>
      </Grid>

      <Grid item xs={6}>
        {ActivityTitleField}
      </Grid>
      <Grid item xs={12}>
        <Box mt={2}>
          <FieldWrapper>
            <Field component={SubActiviesField} name="activity.subActivities" />
          </FieldWrapper>
        </Box>
      </Grid>
    </ShadowedFieldBlock>
  );
};

export default StepSelectorBlock;
