import { Box, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Plus } from '@styled-icons/bootstrap/Plus';
import dragIcon from 'assets/img/icons/drag-handle.svg';
import { InfoTooltip, ThemeButton } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { Field, FieldArray, FieldProps } from 'formik';
import update from 'immutability-helper';
import React, { FC, useCallback } from 'react';
import { Container as DragContainer, Draggable } from 'react-smooth-dnd';
import { IActivity } from 'types/activity.types';
import TextField from 'designSystem/Formik/TextField/TextField';

interface ISubActivitiesFieldProps extends FieldProps<IActivity['subActivities']> {
  mode: 'default' | 'translate';
}

const DragHandle = styled('div')(() => ({
  '&:hover': {
    cursor: 'grab',
  },
}));

const DragIcon = styled('img')(() => ({
  width: 10,
  pointerEvents: 'none',
}));

const Item = styled(Box)(({ theme }) => ({
  display: 'flex !important',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
  width: '100%',
  padding: theme.spacing(1, 0, 0),
  overflow: 'unset !important',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& input': {
    fontSize: '11px !important',
  },

  '& .MuiFormHelperText-root': {
    margin: 0,
    fontSize: 9,
  },

  '& .MuiInputLabel-root': {
    top: 2,
    lineHeight: '1rem',
  },
}));

const SubActivitiesField: FC<ISubActivitiesFieldProps> = ({
  field: { name, value = [] },
  form: { setFieldValue },
  mode,
}) => {
  const onDrop = useCallback(
    ({ removedIndex, addedIndex }: { removedIndex: number | null; addedIndex: number | null }) => {
      if (removedIndex === null) return;
      const subActivities = value[removedIndex];
      const movedSubActivities = update<IActivity['subActivities']>(value, {
        $splice: [
          [removedIndex, 1] as [number, number],
          ...(addedIndex !== null
            ? [
                [addedIndex, 0, subActivities] as [
                  number,
                  number,
                  IActivity['subActivities'][number]
                ],
              ]
            : []),
        ],
      });

      setFieldValue(name, movedSubActivities);
    },
    [name, setFieldValue, value]
  );

  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <>
          <DragContainer
            onDrop={onDrop}
            dragHandleSelector=".drag-handle"
            render={rootRef => (
              <Box ref={rootRef} display="flex" flexDirection="column" gap={1}>
                {value.map((item, index) => (
                  <Draggable
                    key={index}
                    render={() => (
                      <Item>
                        {mode !== 'translate' && (
                          <DragHandle className="drag-handle">
                            <DragIcon src={dragIcon} alt="drag handle" />
                          </DragHandle>
                        )}

                        <Box width="100%">
                          <Field
                            variant="outlined"
                            fullWidth
                            name={`${name}[${index}].title`}
                            placeholder="E.g. Harvest, Cleaning or Drying"
                            component={StyledTextField}
                            size="small"
                            label={`Activity detail ${index + 1}`}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Box>

                        {value.length > 1 && mode !== 'translate' && (
                          <IconButton onClick={() => arrayHelpers.remove(index)}>
                            <Icon name="delete" color="gray-100" size="small" />
                          </IconButton>
                        )}
                      </Item>
                    )}
                  />
                ))}
              </Box>
            )}
          />

          {mode !== 'translate' && (
            <Box pt={1.5} display="flex" alignItems="center" gap={1}>
              <Tooltip title={value.length >= 20 ? 'You can only add up to 20 subActivities.' : ''}>
                <Box>
                  <ThemeButton
                    size="small"
                    color="BLUE_ICE"
                    startIcon={<Plus size={14} />}
                    onClick={() => arrayHelpers.push({ title: '' })}
                    disabled={value.length >= 20}
                  >
                    Add activitiy detail
                  </ThemeButton>
                </Box>
              </Tooltip>
              <InfoTooltip
                text="Add every detailed activity that happens during this overall activity at this site, to give more clarity to the granular steps of the process (e.g. Harvest, Cleaning, Drying, Storage)."
                variant="INFO"
              />
            </Box>
          )}
        </>
      )}
    />
  );
};

export default SubActivitiesField;
